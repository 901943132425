export default defineNuxtRouteMiddleware(async (to: any, from: any) => {
    if (import.meta.client) return;

    const config = useRuntimeConfig();

    // Prevent robot.txt to show every request
    if (to.path.includes('robot.txt')) return;

    // Retain query URL
    const queryURL: URLSearchParams = new URLSearchParams(from.query);
    const query: string = queryURL.toString() ? `?${queryURL}` : '';

    // Get Storyblok cache version
    const storyblokApi = useStoryblokApi();
    const cv = await storyblokApi.get('cdn/spaces/me');
    const startsWith = `${config.public.STORYBLOK_FOLDER}/global/redirects/`;

    const perPage = 100;
    const params = {
        version: config.public.STORYBLOK_VERSION,
        per_page: perPage,
        page: 1,
    };

    let stories: any = [];
    let total: number = 0;

    const url = `https://api.storyblok.com/v2/cdn/stories?starts_with=${startsWith}&per_page=${params.per_page}&page=1&version=${config.public.STORYBLOK_VERSION}&token=${config.public.STORYBLOK_TOKEN}&cv=${cv.data.space.version}`;

    // Get Data from Storyblok (UseFetch to cache result)
    const { data }: any = await useAsyncData(`storyblok-redirect-first`, () =>
        $fetch(url, {
            onResponse(context) {
                // Output all available response headers
                total = Number(context.response.headers.get('total'));
            },
            onResponseError(context) {
                // Output all available response headers
                console.error('Redirect middleware error - First request', context.response.headers);
                return;
            },
        })
    );
    stories = stories.concat(data?.value?.stories);

    const lastPage = total ? Math.ceil(total / perPage) : 1;
    for (let currentPage = 2; currentPage <= lastPage; currentPage++) {
        params.page = currentPage;

        const url = `https://api.storyblok.com/v2/cdn/stories?starts_with=${startsWith}&per_page=${params.per_page}&page=${currentPage}&version=${config.public.STORYBLOK_VERSION}&token=${config.public.STORYBLOK_TOKEN}&cv=${cv.data.space.version}`;

        const { data }: any = await useAsyncData(`storyblok-redirect-${currentPage}`, () =>
            $fetch(url, {
                onResponseError(context) {
                    // Output all available response headers
                    console.error('Redirect middleware error - First request', context.response.headers);
                    return;
                },
            })
        );
        if (!data) {
            break;
        }
        stories = stories.concat(data?.value?.stories);
    }

    // Get URL without trailing slash
    const toUrl: string = to.path.endsWith('/') ? to.path.slice(0, to.path.length - 1) : to.path;

    if (stories.length === 0) return;

    // Get result from Storyblok results, if no results don't redirect
    const redirect = stories?.filter((r: { content: { source_url: string } }) => {
        return r?.content?.source_url === toUrl;
    })[0];
    if (!redirect) return;

    // Check if URL + Storyblok target is the same, if so don't redirect
    if (redirect?.content?.target_story === toUrl) return;

    return navigateTo(redirect?.content?.target_story + query, {
        external: true,
        redirectCode: Number(redirect?.content?.status_code),
    });
});
